import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  ProgressBar,
  Row,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";

import "./TodoForms.scss";
// icn
import { ReactComponent as OTick } from "../../../../../Assets/Images/icn/OrangeTick.svg";
import AddDataForm from "./Component/AddDataForm/Index";
import { getNAQv1Reports, saveTodoResponse, submitPracticeBNAQ, viewToDoData } from "store/services/todoService";
import { SUCCESS_MESSAGE } from "common/constantMessage";
import { todo } from "Routes/paths";
import { clearToDoData } from "store/actions/todo";
import { NAQV1_ID } from "common/constants";
import GenerateNAQv1ReportsPdf from "components/shared/NAQv1Reports"
import * as actions from "store/actions";

const TodoForm = ({ dispatch, todoPages, todoFormDetails, lastActiveStep, user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const [generatingReports, setGeneratingReports] = useState(false);

  const naq1ReportsRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    getToDoData();

    return () => {
      dispatch(clearToDoData());
    }
  }, [])

  // get ToDo data
  const getToDoData = async () => {
    if (!id) {
      return;
    }
    try {
      setIsLoading(true);
      await dispatch(viewToDoData(id));
      setIsLoading(false);
    } catch (err) {
      console.log("Error fetching ToDo details", err);
      setIsLoading(false);
      navigate(todo);
    }
  };

  const saveFormResponse = async (data, isFinalSubmit, isAutoSave) => {
    try {
      const { pageId, values } = data;
      const callback = (response) => {
        // console.log("response", response);
      };

      // values = JSON.stringify(values)
      let formData = {
        isFinalSubmit,
        questions: values,
        pageId,
      };
      let postData = {
        id,
        formData,
      };
      await dispatch(saveTodoResponse(postData));
      
      if(isAutoSave) {
        return;
      }

      // if naq is submitted then generate reports
      if (isFinalSubmit && todoFormDetails.id === NAQV1_ID && todoFormDetails?.additionalDetails?.from_practice_better === true) {
        // await submitPBNAQv1WithReports();
        setGeneratingReports(true);
      }

      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
    } catch (err) {
      console.log(err, "==============================");
      throw new Error("Failed to save form response");
    }
  };

  const handleToDoCompleted = () => {
    // if form is assgined through practice better then wait for the reports to be generated and submitted, otherwise navigate to todo page
    if(todoFormDetails?.additionalDetails?.from_practice_better === true) {
      return;
    }

    SUCCESS_MESSAGE("ToDo has been submitted successfully");
    navigate(todo);
  };

  const submitPBNAQv1WithReports = async (naqBlobs) => {
    let repsonse = await submitPracticeBNAQ(todoFormDetails?.todo_uuid, naqBlobs);
    if(repsonse?.success === true) {
      SUCCESS_MESSAGE("ToDo has been submitted successfully");
      navigate(todo);
    }
  }

  return (
    <>
      <section className="toDo position-relative" style={{ zIndex: "9", overflow: "hidden" }}>
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className="commonContent my-2">
                <div className="top py-2 border-bottom">
                  {todoFormDetails.form_name && (
                    <h2 className="m-0 py-2 text-capitalize fw-bold">{todoFormDetails.form_name}</h2>
                  )}
                </div>
              </div>
            </Col>
            <Col lg="12" className="my-2 position-relative">
              {/* <Button
                onClick={submitPBNAQv1WithReports}
              >
                Generate NAQ v1 Reports
              </Button> */}
              <AddDataForm
                loading={isLoading}
                todoId={id}
                todoPages={todoPages}
                todoFormDetails={todoFormDetails}
                handleSubmit={saveFormResponse}
                onToDoCompleted={handleToDoCompleted}
                initialStep={lastActiveStep}
              />
            </Col>
            <Col lg="12" className="my-2 d-none">
              <Form>
                <Col lg="12" className="my-2">
                  <label htmlFor="" className="form-label m-0 fw-sbold">
                    Label Text
                  </label>
                  <input type="text" className="form-control" />
                </Col>
                <Col lg="12" className="my-2">
                  <textarea
                    type="text"
                    className="form-control"
                    rows={"4"}
                  ></textarea>
                </Col>
                <Col lg="12" className="my-2">
                  <Form.Select
                    aria-label="Default select example"
                    className="form-control"
                  >
                    <option>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </Col>
                <Col lg="12" className="my-2">
                  <div className="py-2 d-flex align-items-start gap-10">
                    <div className="cstmInputCheckWrp position-relative ">
                      <input
                        type="checkbox"
                        className="position-absolute w-100 h-100"
                        name="is_email_invitation"
                      />
                      <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                        <span className="icn d-none">
                          <OTick />
                        </span>
                      </div>
                    </div>
                    <p className=" m-0">
                      Do not email this client an invite to the site{" "}
                    </p>
                  </div>
                </Col>
                <Col lg="12" className="my-2">
                  <div className="py-1 d-flex align-items-start gap-10">
                    <div className="cstmInputCheckWrp radio position-relative ">
                      <input
                        type="radio"
                        name="gender"
                        className="position-absolute w-100 h-100"
                      />
                      <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                        <span className="icn d-none rounded-circle">
                          {/* <OTick /> */}
                        </span>
                      </div>
                    </div>
                    <p className=" m-0 ">Other</p>
                  </div>
                </Col>
              </Form>
            </Col>
            {/* <Col lg="12" className="my-2">
              <div className="commonContent py-3 border-top">
                <p className="m-0">
                  If you'd like to pause this to-do item and go back to your
                  list of to-do items please{" "}
                  <Link to="" className="underline fw-bold">
                    click here
                  </Link>{" "}
                  .
                </p>
                <p className="m-0">
                  We'll save everything off so it's here for you when you come
                  back.
                </p>
              </div>
            </Col> */}
          </Row>
          {/* NAQ v1 reports */}
          {generatingReports && (
            <GenerateNAQv1ReportsPdf
              ref={naq1ReportsRef}
              client={user}
              todoId={id}
              todoFormDetails={todoFormDetails}
              getReports={getNAQv1Reports}
              submitReports={submitPBNAQv1WithReports}
            />
          )}
        </Container>
      </section>
    </>
  );
};

const mapStateToPros = (state) => {
  // console.log("state.ToDo.currentTodoData", state.ToDo.currentTodoData)
  let formId = state.ToDo?.currentTodoData?.formDetails?.id;
  let questionCount = formId === NAQV1_ID ? 0 : 1;
  let todoPages = state.ToDo.currentTodoData.questions.map((page) => {
    let pageWithNumberedQuestions = page.questions.map((question) => {
      question.number = questionCount;
      questionCount++;
      return question;
    });
    page.questions = pageWithNumberedQuestions;
    return page;
  });

  let lastActiveStep = 1;
  if (state.ToDo.currentTodoData.formDetails.additionalDetails) {
    let additionalDetails = state.ToDo.currentTodoData.formDetails.additionalDetails;
    let lastActivePage = additionalDetails.client_last_page || 1;
    todoPages.forEach((page, i) => {
      if (Number(page.form_page_id) === Number(lastActivePage)) {
        lastActiveStep = typeof todoPages[i + 1] !== "undefined" ? (i + 1) : i;
      }
    })
  }

  return {
    user: state.Profile?.user,
    todoPages: todoPages,
    todoFormDetails: state.ToDo.currentTodoData.formDetails,
    loader: state.ToDo.loading,
    lastActiveStep,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(TodoForm);